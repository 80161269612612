import React, { useState, useRef, useEffect } from "react";
import { Box, Text, useMediaQuery, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import Paper from "@material-ui/core/Paper";
import { BASE_MEDIA } from "src/api";

const FilmPlayer = ({ audioUrl, onClose, posters }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const imageUrls = posters.map((poster) =>
    poster.coverUrl?.startsWith("media/")
      ? `${BASE_MEDIA}/${poster.coverUrl}`
      : poster.coverUrl
  );

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleClose = () => {
    setIsPlaying(false);
    onClose(); // Call the onClose callback to close the overlay
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleTimeUpdate = () => {
      if (audioElement) {
        const currentTime = Math.floor(audioElement.currentTime);

        if (
          imageUrls.length > 1 &&
          currentTime % 10 === 0 &&
          currentTime !== 0 &&
          currentTime !== lastUpdateTime
        ) {
          setLastUpdateTime(currentTime);
          setCurrentImage((prevImage) => (prevImage + 1) % imageUrls.length);
        }
      }
    };

    if (audioElement) {
      audioElement.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, [audioRef, imageUrls, lastUpdateTime]);

  const [isLargeScreen] = useMediaQuery("(min-width: 36em)");

  return (
    <div className="overlay-container">
      <style>
        {`
          .overlay-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
          }

          @keyframes fadeInOut {
            0% {
              opacity: 0;
            }
            5% {
              opacity: 1;
            }
            95% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        `}
      </style>
      <Box
        w={isLargeScreen ? "80vw" : "90vw"}
        h={isLargeScreen ? "90vh" : "80vh"}
        overflow="hidden"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        <Box backgroundColor={"black"} width="100%" height="100%">
          <Box
            key={currentImage}
            backgroundImage={imageUrls[currentImage]}
            backgroundRepeat="no-repeat"
            objectFit={"contain"}
            backgroundSize="cover"
            backgroundPosition="center"
            width={isLargeScreen ? "80%" : "100%"}
            height="94%"
            animation={
              imageUrls.length > 1 && isPlaying
                ? "fadeInOut 10s ease-in-out forwards"
                : "none"
            }
            margin={"auto"}
          >
            <Paper
              elevation={4}
              style={{
                background: "rgba(0, 0, 0, .0)",
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                borderRadius: "10px",
                display: "flex",
                alignItems: posters[currentImage].valign,
              }}
            >
              <Text
                color={posters[currentImage].nameColor}
                fontFamily="Calibri (Body)"
                fontSize={posters[currentImage].nameSize}
                style={{
                  fontFamily: "Droid",
                  textAlign: posters[currentImage].halign,
                  width: "100%",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                }}
              >
                {posters[currentImage].name}
              </Text>
            </Paper>
          </Box>
        </Box>
        <audio
          ref={audioRef}
          src={audioUrl}
          autoPlay={true}
          controls
          controlsList="nodownload"
          onPlay={handlePlayPause}
          onPause={handlePlayPause}
          style={{
            width: isLargeScreen ? "80vw" : "90vw",
            height: "6%",
            position: "absolute",
            bottom: "0",
            backgroundColor: "#F1F3F4",
          }}
          playsInline
        />
      </Box>
      <IconButton
        aria-label="Close"
        icon={<CloseIcon />}
        variant="ghost"
        onClick={handleClose}
        size="md"
        position="absolute"
        top="10px"
        right="10px"
        borderRadius="full"
        _hover={{ bg: "gray.100" }}
      />
    </div>
  );
};

export default FilmPlayer;
