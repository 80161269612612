import React from "react";
import styles from "../styles/videos.module.css";
import ReactPlayer from "react-player";
import VideoDescriptionModal from "./Modal";
import { useDisclosure } from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
import { Training } from "src/types";

interface VideoProps {
  video: Training;
}

const Video = ({ video }: VideoProps) => {
  const { isOpen: isInfoModalOpen, onOpen: onInfoModalOpen, onClose: onInfoModalClose } = useDisclosure();
  const { url, text, html, title, lecturerName } = video;

  if (!url) {
    return null;
  }

  return (
    <>
      <div className={styles.videoContainer}>
        <div className={styles.playerWrapper}>
          <ReactPlayer
            className={styles.reactPlayer}
            url={video.url}
            width="100%"
            height="100%"
            controls
            playsinline
          />
        </div>
        <div className={styles.videoContent}>
          <div className={styles.videoTitleContainer}>
            <h2 className={styles.videoTitle}>{title}</h2>
            <BsInfoCircle
              size={"1rem"}
              cursor={"pointer"}
              color="gray"
              onClick={onInfoModalOpen}
              className={styles.infoIcon}
            />
          </div>
          <p className={styles.videoLecturer}>{lecturerName}</p>
          <p className={styles.videoDescription}>{text}</p>
        </div>
      </div>
      <VideoDescriptionModal
        isOpen={isInfoModalOpen}
        onClose={onInfoModalClose}
        title={title}
        description={html}
        lecturerName={lecturerName}
      />
    </>
  );
};

export default Video;