import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PlaybackState {
  isPlaying: boolean;
  currentTrackUrl: string | null;
  currentTime: number;
  isLooping: boolean;
}

const playbackSlice = createSlice({
  name: "playback",
  initialState: {
    isPlaying: false,
    currentTrackUrl: null,
    currentTime: 0,
    isLooping: false,
  } as PlaybackState,
  reducers: {
    play: (state, action) => {
      state.isPlaying = true;
      state.currentTrackUrl = action.payload;
      state.isLooping = false;
    },
    pause: (state) => {
      state.isPlaying = false;
    },
    updateCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
    toggleTrackLooping: (state) => {
      state.isLooping = !state.isLooping;
    },
  },
});

export const { play, pause, updateCurrentTime, toggleTrackLooping } =
  playbackSlice.actions;
export default playbackSlice.reducer;
