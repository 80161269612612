import React from "react";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useSelector, useDispatch } from "src/store";
import { toggleArea, setMaximize } from "src/store/media";
import IndepMediaPlayer from "src/components/IndepMediaPlayer";
import { useIntl } from "react-intl";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

const videos = {
  ar: {
    clear: "https://vimeo.com/744319550",
    protect: "https://vimeo.com/744319262",
  },
  en: {
    clear: "https://vimeo.com/744155737",
    protect: "https://vimeo.com/744319775",
  },
};

const VideoModal = () => {
  const { display, maximize, clear } = useSelector((state) => ({
    display: state.media.display,
    maximize: state.media.maximize,
    clear: state.media.clear,
  }));
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const property = clear ? "clear" : "protect";
  const videoUrl = videos[locale][property];

  const handleClose = () => {
    dispatch(toggleArea({ value: false }));
    dispatch(setMaximize({ value: false }));
  };

  const handleToggleMaximize = () => {
    dispatch(setMaximize({ value: !maximize }));
  };

  if (!display) return null;

  return (
    <Box
      position="fixed"
      bottom={0}
      right={0}
      zIndex={100}
      bg="gray.800"
      color="white"
      boxShadow="0 0 20px rgba(0,0,0,0.3)"
      borderRadius={maximize ? "0" : "10px 0 0 0"}
      overflow="hidden"
      transition="all 0.3s ease"
      width={maximize ? "100%" : ["100%", "100%", "350px", "400px"]}
      height={maximize ? "100%" : ["250px", "300px", "350px", "400px"]}
    >
      <Flex direction="column" height="100%">
        <Flex justifyContent="flex-end" alignItems="center" p={2} bg="gray.700">
          <IconButton
            icon={maximize ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
            onClick={handleToggleMaximize}
            aria-label={maximize ? "Minimize" : "Maximize"}
            size="sm"
            mr={2}
            colorScheme="teal"
            variant="ghost"
          />
          <IconButton
            icon={<CloseIcon />}
            onClick={handleClose}
            aria-label="Close"
            size="sm"
            colorScheme="red"
            variant="ghost"
          />
        </Flex>
        <Box flexGrow={1} overflow="hidden">
          <IndepMediaPlayer
            src={videoUrl}
            disableSeeking={false}
            autoPlay={true}
            loop={false}
            styles={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default VideoModal;
