import React, { useState } from "react";
import ProgressBar from "src/views/Prepration/Missions/ProgressBar";
import { Box, HStack, VStack, Heading, Text } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import InputWithAdd from "src/components/InputWithAdd";
import ItemCard from "src/components/ItemCard";
import Link from "src/components/Link/Link";
import TextArea from "src/components/Textarea";
import { MagicGoalProblems } from "src/types";
import EditObstacleModal from "./EditObstacleModal";
import * as mutation from "src/api/affirmation/magic/mutation";
import { useMutation, useQueryClient } from "react-query";
import TextLoop from "react-text-loop";
import { MEDIA_URL } from "src/api";

type localProblemList = MagicGoalProblems[];

const MagicMain = (props: any) => {
  const { changeTab } = props;
  const intl = useIntl();
  const [stepState, setStepState] = useState<number>(0);
  const [goal, setGoal] = useState<string>("");
  const [activities, setActivities] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [obstacleData, setObstacleData] = useState<localProblemList>([]);
  const [tempObstacle, setTempObstacle] = useState<string>("");
  const [tempObstacleModal, setTempObstacleModal] = useState<string>("");
  const [tempSolution, setTempSolution] = useState<string>("");
  const [tempSolutionModal, setTempSolutionModal] = useState<string>("");
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [inputsArray, setInputsArrray] = useState([""]);
  const [editIndex, setEditIndex] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");
  const audioRef = React.useRef<any>(null);
  const client = useQueryClient();
  const { mutateAsync: createMutation } = useMutation(mutation.createGoal, {
    onSuccess: (res) => client.invalidateQueries("listMagic"),
  });

  const resetSteps = async () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    await createMutation({
      name: goal,
      note: notes,
      activity: activities,
      problems: obstacleData,
    });
    setGoal("");
    setNotes("");
    setObstacleData([]);
    setActivities("");
    changeTab();
    setStepState(0);
  };

  const stepJumber = () => {
    if (stepState === 0) {
      goal === "" && setDisableBtn(true);
      if (goal !== "") {
        audioRef.current.play();
        return setStepState(1);
      }
    }
    if (stepState === 1) {
      setDisableBtn(true);
      return setStepState(2);
    }
    if (stepState === 2 && activities !== "") {
      setDisableBtn(true);
      return setStepState(3);
    }
    if (stepState === 3 && obstacleData.length !== 0) {
      setDisableBtn(true);
      // audioRef.current.pause();
      return setStepState(4);
    }
  };

  const editModal = async (id: number, obstcale: string, solution: string) => {
    await setTempObstacleModal(obstcale);
    await setTempSolutionModal(solution);
    await setEditIndex(id);
    setOpenModal(true);
  };
  const onChangeModal = (...args: any) => {
    if (args[1] === "s" && args[0]) {
      setTempSolutionModal(args[0].target.value);
    } else {
      setTempObstacleModal(args[0]?.target.value);
    }
  };
  const submitModal = async () => {
    const newObstacleData = [...obstacleData];
    newObstacleData[editIndex] = {
      obstacle: tempObstacleModal,
      solution: tempSolutionModal,
    };
    await setObstacleData(newObstacleData);
    setTempObstacleModal("");
    setTempSolutionModal("");
    setOpenModal(false);
  };
  return (
    <VStack maxW={"812px"} justifyContent={"center"} width={"100%"}>
      <Box width={"100%"} maxWidth={"440px"} textAlign={"right"}>
        <ProgressBar
          mt={"40px"}
          magic
          step={stepState}
          setStep={setStepState}
        />
        <audio ref={audioRef} playsInline>
          <source
            src={`${MEDIA_URL}/static/magicmanifest.mp3`}
            type="audio/mpeg"
          />
        </audio>
      </Box>
      {stepState === 0 && (
        <Box textAlign={"left"} width={"100%"}>
          <Heading textStyle={"h5"}>
            <FormattedMessage
              id={"magic.steponeheader"}
              defaultMessage={"قم بإدخال ما الذى تريده ثم لماذا تريده"}
            />
          </Heading>
          <InputWithAdd
            id={"magicInput"}
            onKeyDown={(event: any) => {
              if (event.keyCode === 13) {
                document.getElementById(`magicInput0`)?.focus();
              }
            }}
            magic={true}
            style={{ width: "80%" }}
            noAdd
            mt={"20px"}
            mb={"20px"}
            placeholder={intl.formatMessage({ id: "magic.whatdoyouwant" })}
            value={goal}
            onChange={(e: any) => setGoal(e.target.value)}
          />
          {inputsArray.map((input, idx) => {
            return (
              <InputWithAdd
                key={idx}
                onKeyDown={async (event: any) => {
                  if (event.keyCode === 13) {
                    await setInputsArrray([...inputsArray, ""]);
                    setDisableBtn(true);
                    document.getElementById(`magicInput${idx + 1}`)?.focus();
                  }
                }}
                id={`magicInput${idx}`}
                onClick={() => {
                  setInputsArrray([...inputsArray, ""]);
                  setDisableBtn(true);
                }}
                onChange={(e: any) =>
                  e.target.value === "" || goal === ""
                    ? setDisableBtn(true)
                    : setDisableBtn(false)
                }
                style={{ width: "80%" }}
                mt={"20px"}
                mb={"20px"}
                placeholder={intl.formatMessage({
                  id: "magic.forwhatdoyouwant",
                })}
              />
            );
          })}
        </Box>
      )}
      {stepState === 1 && (
        <Box width={"100%"} textAlign={"center"} mt={"74px"}>
          <Heading width={"100%"} textStyle={"h5"}>
            <FormattedMessage
              id={"magic.imagin"}
              defaultMessage={"تخيل المطلوب"}
            />
          </Heading>
          <TextLoop interval={10000}>
            <Heading
              className={"blink_intention"}
              width={"100%"}
              my={"30px"}
              fontWeight={"normal"}
              color={"#2895ff"}
              textStyle={"h2"}
            >
              <FormattedMessage id={"magic.howdoesitlook"} />
            </Heading>
            <Heading
              width={"100%"}
              className={"blink_intention"}
              my={"30px"}
              fontWeight={"normal"}
              color={"#2895ff"}
              textStyle={"h2"}
            >
              <FormattedMessage id={"magic.looptext1"} />
            </Heading>
            <Heading
              width={"100%"}
              className={"blink_intention"}
              my={"30px"}
              fontWeight={"normal"}
              color={"#2895ff"}
              textStyle={"h2"}
            >
              <FormattedMessage id={"magic.looptext2"} />
            </Heading>
            <Heading
              width={"100%"}
              className={"blink_intention"}
              my={"30px"}
              fontWeight={"normal"}
              color={"#2895ff"}
              textStyle={"h2"}
            >
              <FormattedMessage id={"magic.looptext3"} />
            </Heading>
            <Heading
              width={"100%"}
              className={"blink_intention"}
              my={"30px"}
              fontWeight={"normal"}
              color={"#2895ff"}
              textStyle={"h2"}
            >
              <FormattedMessage id={"magic.looptext4"} />
            </Heading>
          </TextLoop>
        </Box>
      )}
      {stepState === 2 && (
        <Box textAlign={"left"} width={"100%"}>
          <Heading fontSize={"24px"} textStyle={"h2"}>
            <FormattedMessage
              id={"magic.allowtransfiguration"}
              defaultMessage={"السماح بالتجلي"}
            />
          </Heading>
          <Heading mt={"24px"} textStyle={"h5"}>
            <FormattedMessage
              id={"magic.writeenjoyableactivites"}
              defaultMessage={
                "أكتب أنشطة أو اعمالاً تقوم بها تجعلك فى حالة إستمتاع"
              }
            />
          </Heading>
          <TextArea
            placeholder={intl.formatMessage({ id: "activities" })}
            onChange={(e: any) => {
              setActivities(e.target.value);
              e.target.value === ""
                ? setDisableBtn(true)
                : setDisableBtn(false);
            }}
          />
        </Box>
      )}
      {stepState === 3 && (
        <Box textAlign={"left"} width={"100%"}>
          <Heading textStyle={"h5"}>
            <FormattedMessage
              id={"magic.obstaclesandsolutions"}
              defaultMessage={"المعوقات والحلول"}
            />
          </Heading>
          <>
            <InputWithAdd
              id={"magicObstaclesInput"}
              onKeyDown={(event: any) => {
                if (event.keyCode === 13) {
                  document.getElementById("inputMagicSolution")?.focus();
                }
              }}
              magic={true}
              value={tempObstacle}
              noAdd
              mt={"20px"}
              mb={"20px"}
              placeholder={intl.formatMessage({ id: "magic.obstacles" })}
              onChange={(e: any) => {
                setTempObstacle(e.target.value);
              }}
            />
            <InputWithAdd
              onKeyDown={async (event: any) => {
                if (event.keyCode === 13) {
                  await setObstacleData([
                    ...obstacleData,
                    { obstacle: tempObstacle, solution: tempSolution },
                  ]);
                  setTempObstacle("");
                  setTempSolution("");
                  setDisableBtn(false);
                  document.getElementById("magicObstaclesInput")?.focus();
                }
              }}
              id={"inputMagicSolution"}
              onClick={async () => {
                await setObstacleData([
                  ...obstacleData,
                  { obstacle: tempObstacle, solution: tempSolution },
                ]);
                setTempObstacle("");
                setTempSolution("");
                setDisableBtn(false);
              }}
              mt={"20px"}
              mb={"20px"}
              placeholder={intl.formatMessage({ id: "magic.solutions" })}
              value={tempSolution}
              onChange={(e: any) => {
                setTempSolution(e.target.value);
              }}
            />
          </>
          <>
            <HStack spacing={44}>
              <Text minWidth={"150px"} fontFamily={"tajwal"}>
                {intl.formatMessage({ id: "magic.obstacles" })}
              </Text>
              <Text fontFamily={"tajwal"}>
                {intl.formatMessage({ id: "magic.solutions" })}
              </Text>
            </HStack>

            {obstacleData?.map((problem: any, id: any) => {
              return (
                <ItemCard
                  divChild
                  pl={"0"}
                  key={id}
                  mb={5}
                  background={"transparent"}
                  boxShadow={"none"}
                  edit={() => {
                    editModal(id, problem.obstacle, problem.solution);
                  }}
                  remove={() =>
                    setObstacleData(
                      obstacleData.filter(
                        (e) => e.obstacle !== problem.obstacle
                      )
                    )
                  }
                >
                  <HStack spacing={44} justify={"space-between"} width={"100%"}>
                    <Text
                      m={"0"}
                      minWidth={"150px"}
                      color={"#8b8b8b"}
                      fontFamily={"tajwal"}
                    >
                      {problem.obstacle}
                    </Text>
                    <Text m={"0"} color={"#8b8b8b"} fontFamily={"tajwal"}>
                      {problem.solution}
                    </Text>
                  </HStack>
                </ItemCard>
              );
            })}
          </>
        </Box>
      )}
      {stepState === 4 && (
        <Box textAlign={"left"} width={"100%"}>
          <Heading fontSize={"24px"} textStyle={"h2"}>
            <FormattedMessage
              id={"magic.notes"}
              defaultMessage={"الملاحظات "}
            />
          </Heading>
          <Heading mt={"24px"} textStyle={"h5"}>
            <FormattedMessage
              id={"magic.notesheader"}
              defaultMessage={
                "أكتب هنا بعض الملاحظات واترك هذا الهدف يتجلي وأحفظه فى الصندوق السحري"
              }
            />
          </Heading>
          <TextArea
            placeholder={intl.formatMessage({ id: "magic.notes" })}
            onChange={(e: any) => {
              setNotes(e.target.value);
              e.target.value === ""
                ? setDisableBtn(true)
                : setDisableBtn(false);
            }}
          />
        </Box>
      )}
      <div>
        <Link
          disabled={disableBtn}
          mb={"33px"}
          onClick={() => (stepState === 4 ? resetSteps() : stepJumber())}
          asBtn={true}
        >
          {stepState !== 4 ? (
            <FormattedMessage id="send" defaultMessage={"إرسال"} />
          ) : (
            <FormattedMessage id="save" defaultMessage={"حفظ"} />
          )}
        </Link>
      </div>
      <EditObstacleModal
        submitModal={submitModal}
        onChangeProp={onChangeModal}
        open={openModal}
        obstacle={tempObstacleModal}
        solution={tempSolutionModal}
        closeModal={() => setOpenModal(false)}
      />
    </VStack>
  );
};

export default MagicMain;
