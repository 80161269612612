import React, { CSSProperties, useReducer, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import {
  Button,
  Grid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  Box,
} from "@chakra-ui/react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import getReadableTime from "src/helpers/getReadableTime";

const IndepMediaPlayer = ({
  src,
  loop = false,
  disableSeeking = false,
  muted = false,
  styles = {},
  noControls = false,
  onEnded,
  onPlay,
  onPause,
  autoPlay,
}) => {
  const [isPlay, togglePlay] = useReducer(
    (s, next) => (next == null ? !s : next),
    autoPlay ?? false
  );
  const [volume, setVolume] = React.useState(muted ? 0 : 1);
  const type = src.endsWith(".mp3") || src.endsWith(".ogg") ? "audio" : "video";

  const ref = useRef(null);
  const handle = useFullScreenHandle();
  const [progress, setProgress] = React.useState(0);
  const [duration, setDuration] = React.useState(0);

  useEffect(() => {
    if (ref.current) {
      ref.current.seekTo(0);
    }
  }, [src]);

  const containerStyles = {
    width: "100%",
    height: "100%",
    ...styles,
  };

  return (
    <Box style={containerStyles}>
      <FullScreen handle={handle}>
        <ReactPlayer
          ref={ref}
          url={src}
          playing={isPlay}
          loop={loop}
          volume={volume}
          muted={muted}
          width="100%"
          height="100%"
          style={{ pointerEvents: disableSeeking ? "none" : "auto" }}
          onPlay={() => {
            togglePlay(true);
            onPlay && onPlay();
          }}
          onPause={() => {
            togglePlay(false);
            onPause && onPause();
          }}
          onEnded={() => {
            onEnded && onEnded();
            togglePlay(false);
          }}
          onDuration={setDuration}
          onProgress={(state) => setProgress(state.playedSeconds)}
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
            vimeo: {
              playerOptions: { background: 1 },
            },
          }}
          playsInline
        />
        {!noControls && (
          <Box
            // i need this display flex
            display="flex"
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bg="rgba(0,0,0,0.5)"
            color="white"
            p={2}
            templateColumns="auto 1fr auto auto"
            gap={2}
            alignItems="center"
          >
            <Button
              onClick={() => togglePlay()}
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                cursor: "pointer",
                fontSize: "1.5rem",
              }}
            >
              {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
            </Button>
            <Slider
              value={progress}
              max={duration}
              onChange={(v) => ref.current?.seekTo(v)}
              isDisabled={disableSeeking}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text
              fontSize="sm"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {getReadableTime(progress)} / {getReadableTime(duration)}
            </Text>
            <Button
              onClick={() => setVolume(volume === 0 ? 1 : 0)}
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                cursor: "pointer",
                fontSize: "1.5rem",
              }}
            >
              {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </Button>
            {type === "video" && (
              <Button
                onClick={handle.active ? handle.exit : handle.enter}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.5rem",
                }}
              >
                {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </Button>
            )}
          </Box>
        )}
      </FullScreen>
    </Box>
  );
};

export default IndepMediaPlayer;
