import React, { useState } from "react";
import {
  Wrap,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  VStack,
  ModalOverlay,
  Heading,
  useDisclosure,
  Box,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import TextLoop from "react-text-loop";
import NewItemButton from "src/components/NewItemButton";
import InputWithAdd from "src/components/InputWithAdd";
import SubHeader from "src/components/SubHeader";
import Link from "src/components/Link/Link";
import { cameraIcon } from "src/assets/icons";
import person from "src/assets/images/person.jpg";
import * as mutation from "src/api/affirmation/Stroy/mutation";
import { useMutation, useQueryClient } from "react-query";
import { uploadFile, MEDIA_URL } from "src/api";
import { useDispatch, useSelector } from "src/store";
import { playMedia, stopMedia } from "src/store/media";
//work around media server
import useIsDev from "src/helpers/useIsDev";
import { useHistory } from "react-router-dom";

const CreateStory = () => {
  const isDev = useIsDev();
  const client = useQueryClient();
  const { mutateAsync: createMutation } = useMutation(mutation.createStory, {
    onSuccess: () => client.invalidateQueries("listStory"),
  });

  //current user
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [storyTime, setStoryTime] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [storyName, setStoryName] = useState<string>("");
  const [storyImg, setStoryImg] = useState<string>("");
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState<any>(null);
  const history = useHistory();

  const modalClose = () => {
    dispatch(stopMedia());
    onClose();
    setStoryImg("");
    setStoryTime(0);
    setStoryName("");
    setSubmitForm(false);
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
  };

  const uploadImage = async (e: any) => {
    setLoading(true);
    await uploadFile(
      e.target.files[0],
      () => {},
      (url: string) => setStoryImg(`${MEDIA_URL}/${url}`),
      undefined,
      user?.accessToken
    );
    setLoading(false);
  };
  const resetTime = () => {
    setStoryTime(0);
  };
  const resetinput = () => {
    setStoryName("");
  };
  const submitBtn = () => {
    setSubmitForm(true);
    dispatch(
      playMedia({
        source: `${MEDIA_URL}/static/newstory.mp3`,
        name: "new-story",
        type: "audio",
        id: "new-story-audio",
        loop: true,
        pageUrl: history.location.pathname,
      })
    );
    createMutation({
      name: storyName,
      imageUrl: isDev ? "https://sal125.smartsway.com/logo-dark.png" : storyImg,
      duration: storyTime,
    });

    //set the timeout state to be able to stop it later
    setCurrentTimeout(setTimeout(modalClose, storyTime * 60 * 1000));
  };
  return (
    <Box borderRadius={"30px"}>
      <Modal isCentered isOpen={isOpen} onClose={modalClose}>
        <ModalOverlay />
        <ModalContent
          px={"10px"}
          maxWidth={"720px"}
          width={"100%"}
          borderRadius={"30px"}
        >
          <ModalCloseButton />
          <ModalBody>
            {!submitForm ? (
              <VStack>
                <SubHeader
                  fontSize={"24px"}
                  mt={"20px"}
                  width={"100%"}
                  textAlign={"left"}
                  formatId={"story.name"}
                />
                <Box w={"100%"}>
                  {" "}
                  <InputWithAdd
                    style={{ width: "80%" }}
                    noAdd
                    mt={"-20px"}
                    mb={"20px"}
                    onChange={(e: any) =>
                      e.target.value === ""
                        ? resetinput()
                        : setStoryName(e.target.value)
                    }
                  />
                </Box>
                <SubHeader
                  fontSize={"24px"}
                  mt={"20px"}
                  width={"100%"}
                  textAlign={"left"}
                  formatId={"period"}
                />
                <Wrap mt={"20px"} mb={"24px"}>
                  <Link
                    asBtn={true}
                    mx={"5px"}
                    lineHeight={"1.38"}
                    onClick={() =>
                      storyTime !== 5 ? setStoryTime(5) : resetTime()
                    }
                    isSecondary={!(storyTime === 5)}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    maxWidth={"112px"}
                    maxHeight={"32px"}
                    borderRadius={15}
                    py={1}
                  >
                    <FormattedMessage id={"minutes"} /> 5
                  </Link>
                  <Link
                    asBtn={true}
                    mx={"5px"}
                    lineHeight={"1.38"}
                    onClick={() =>
                      storyTime !== 10 ? setStoryTime(10) : resetTime()
                    }
                    isSecondary={!(storyTime === 10)}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    maxWidth={"112px"}
                    maxHeight={"32px"}
                    borderRadius={15}
                    py={1}
                  >
                    <FormattedMessage id={"minutes"} /> 10
                  </Link>
                  <Link
                    asBtn={true}
                    mx={"5px"}
                    lineHeight={"1.38"}
                    onClick={() =>
                      storyTime !== 15 ? setStoryTime(15) : resetTime()
                    }
                    isSecondary={!(storyTime === 15)}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    maxWidth={"112px"}
                    maxHeight={"32px"}
                    borderRadius={15}
                    py={1}
                  >
                    <FormattedMessage id={"minutes"} /> 15
                  </Link>
                  <Link
                    asBtn={true}
                    mx={"5px"}
                    lineHeight={"1.38"}
                    onClick={() =>
                      storyTime !== 20 ? setStoryTime(20) : resetTime()
                    }
                    isSecondary={!(storyTime === 20)}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    maxWidth={"112px"}
                    maxHeight={"32px"}
                    borderRadius={15}
                    py={1}
                  >
                    <FormattedMessage id={"minutes"} /> 20
                  </Link>
                  <Link
                    asBtn={true}
                    mx={"5px"}
                    lineHeight={"1.38"}
                    onClick={() =>
                      storyTime !== 30 ? setStoryTime(30) : resetTime()
                    }
                    isSecondary={!(storyTime === 30)}
                    fontSize={"16px"}
                    fontWeight={"normal"}
                    maxWidth={"112px"}
                    maxHeight={"32px"}
                    borderRadius={15}
                    py={1}
                  >
                    <FormattedMessage id={"minutes"} /> 30
                  </Link>
                </Wrap>
                <VStack maxWidth={"592px"} align={"center"} maxHeight={"349px"}>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Box
                      mb={"32px"}
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                      maxWidth={"182px"}
                      backgroundImage={
                        storyImg === ""
                          ? `url( ${person} )`
                          : `url( ${storyImg} )`
                      }
                      width={"100%"}
                      height={"182px"}
                      borderRadius={"50%"}
                      position={"relative"}
                    >
                      <img
                        style={{
                          position: "absolute",
                          left: "71px",
                          top: "164px",
                        }}
                        src={cameraIcon}
                        alt="Add a Story button"
                      />
                      <Input
                        style={{
                          width: "24px",
                          height: "24px",
                          opacity: "0",
                          borderRadius: "50%",
                          left: "67px",
                          top: "167px",
                          cursor: "pointer",
                          position: "absolute",
                        }}
                        onChange={uploadImage}
                        type="file"
                        accept="image/*"
                      />
                    </Box>
                  )}
                  <div>
                    <Link
                      disabled={
                        (!isDev && storyImg === "") ||
                        storyName === "" ||
                        storyTime === 0 ||
                        loading
                      }
                      mb={"33px"}
                      onClick={submitBtn}
                      asBtn={true}
                    >
                      <FormattedMessage
                        id="story.start"
                        defaultMessage={"إبدأ"}
                      />
                    </Link>
                  </div>
                </VStack>
              </VStack>
            ) : (
              <>
                <VStack spacing={2}>
                  <Heading
                    fontSize={"30px"}
                    fontWeight={"bold"}
                    textStyle={"h5"}
                  >
                    {storyName}
                  </Heading>
                  <Box
                    mb={"32px"}
                    style={{
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                    maxWidth={"182px"}
                    backgroundImage={`url( ${storyImg} )`}
                    width={"100%"}
                    height={"182px"}
                    borderRadius={"50%"}
                    position={"relative"}
                  ></Box>

                  <TextLoop springConfig={{ stiffness: 180, damping: 8 }}>
                    <div>
                      <Text
                        color={"#2895ff"}
                        fontFamily={"Calibri (Body)"}
                        fontSize={"24px"}
                      >
                        <FormattedMessage
                          id="story.iloveyou"
                          defaultMessage={"احبك"}
                        />
                      </Text>
                    </div>
                    <div>
                      <Text
                        color={"#2895ff"}
                        fontFamily={"Calibri (Body)"}
                        fontSize={"24px"}
                      >
                        <FormattedMessage
                          id="story.forgiveme"
                          defaultMessage={"سامحنى"}
                        />
                      </Text>
                    </div>
                    <div>
                      <Text
                        color={"#2895ff"}
                        fontFamily={"Calibri (Body)"}
                        fontSize={"24px"}
                      >
                        <FormattedMessage
                          id="story.iamsorry"
                          defaultMessage={"اسف"}
                        />
                      </Text>
                    </div>
                    <div>
                      <Text
                        color={"#2895ff"}
                        fontFamily={"Calibri (Body)"}
                        fontSize={"24px"}
                      >
                        <FormattedMessage
                          id="story.thanks"
                          defaultMessage={"شكرا"}
                        />
                      </Text>
                    </div>
                  </TextLoop>
                </VStack>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <NewItemButton onClick={onOpen}>
        <FormattedMessage id="story.create" defaultMessage="إضافة قصة جديدة" />
      </NewItemButton>
    </Box>
  );
};

export default CreateStory;
