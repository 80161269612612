import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "src/store";
import {
  playNextTrack,
  updatePlaylistCurrentTrackProgress,
  updatePlaylistCurrentTrackDuration,
} from "src/store/media";

const PlaylistPlayer: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const {
    playlist,
    playing: isPlaying,
    currentPlaylistTrackIndex,
    volume,
  } = useSelector((state) => state.media);

  const dispatch = useDispatch();
  // const handleSeek = (
  //   e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  // ) => {
  //   e.preventDefault();
  //   if (audioRef.current && duration) {
  //     const rect = (e.target as HTMLDivElement).getBoundingClientRect();
  //     let clientX = 0;

  //     if ("touches" in e) {
  //       // Touch event
  //       clientX = e.touches[0].clientX;
  //     } else {
  //       // Mouse event
  //       clientX = (e as React.MouseEvent<HTMLDivElement>).clientX;
  //     }

  //     const clickX = clientX - rect.left;
  //     const width = rect.width;

  //     audioRef.current.currentTime = (clickX / width) * duration;
  //   }
  // };

  useEffect(() => {
    if (playlist.length === 0) return;

    if (!audioRef.current) {
      audioRef.current = new Audio(
        playlist[currentPlaylistTrackIndex].audioUrl
      );
    } else {
      audioRef.current.src = playlist[currentPlaylistTrackIndex].audioUrl;
    }
    audioRef.current.volume = volume;
    if (isPlaying) {
      audioRef.current.play();
    }

    const handleTrackEnd = () => {
      dispatch(
        playNextTrack({
          playlist,
        })
      );
    };
    audioRef.current.addEventListener("ended", handleTrackEnd);

    const setAudioData = () => {
      if (audioRef.current) {
        dispatch(
          updatePlaylistCurrentTrackDuration({
            duration: isNaN(audioRef.current.duration)
              ? 0
              : audioRef.current.duration,
          })
        );
        dispatch(
          updatePlaylistCurrentTrackProgress({
            progress: audioRef.current.currentTime,
          })
        );
      }
    };
    audioRef.current.addEventListener("loadedmetadata", setAudioData);

    return () => {
      audioRef.current?.removeEventListener("ended", handleTrackEnd);
      audioRef.current?.removeEventListener("loadedmetadata", setAudioData);
      // audioRef.current?.pause();
    };
  }, [currentPlaylistTrackIndex, dispatch, playlist]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const updateProgress = () => {
      if (audioRef.current) {
        const { currentTime, duration } = audioRef.current;
        dispatch(
          updatePlaylistCurrentTrackDuration({
            duration: isNaN(duration) ? 0 : duration,
          })
        );
        dispatch(
          updatePlaylistCurrentTrackProgress({
            progress: currentTime,
          })
        );
      }
    };

    audioRef.current?.addEventListener("timeupdate", updateProgress);

    return () => {
      audioRef.current?.removeEventListener("timeupdate", updateProgress);
    };
  }, [isPlaying, currentPlaylistTrackIndex, dispatch]);

  useEffect(() => {
    const handleError = (e: Event) => {
      console.error("Error loading audio:", e);
    };

    audioRef.current?.addEventListener("error", handleError);

    return () => {
      audioRef.current?.removeEventListener("error", handleError);
    };
  }, [currentPlaylistTrackIndex]);

  return <></>;
};

export default PlaylistPlayer;
