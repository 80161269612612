import { Box, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { StoryList } from "src/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getList } from "src/api/affirmation/Stroy/query";
import ItemCard from "src/components/ItemCard";
import * as mutation from "src/api/affirmation/Stroy/mutation";
import ViewStoryModal from "./ViewStoryModal";
import { useDispatch } from "src/store";
import { playMedia, stopMedia } from "src/store/media";
import { MEDIA_URL } from "src/api";
import { useHistory } from "react-router-dom";

const StoryItems = () => {
  const dispatch = useDispatch();
  const client = useQueryClient();
  const [storyName, setStoryName] = useState<string>("");
  const [imageURL, setImageURL] = useState<string>("");
  const [durationState, setDurationState] = useState<number>(0);
  const [edit, setEdit] = useState<boolean>(false);
  const [storyId, setStoryId] = useState<number>(0);
  const closeMedia = () => dispatch(stopMedia());
  const { data } = useQuery<StoryList, Error, StoryList>(...getList);
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: deleteStoryMutation } = useMutation(
    mutation.deleteStory,
    {
      onSuccess: () => client.invalidateQueries("listStory"),
    }
  );
  const handleViewModal = (
    id: number | undefined,
    name: string,
    imageURL: string,
    duration: number
  ) => {
    setEdit(false);
    setStoryName(name);
    setImageURL(imageURL);
    setDurationState(duration);
    onOpen();
    dispatch(
      playMedia({
        source: `${MEDIA_URL}/static/newstory.mp3`,
        name: "new-story",
        type: "audio",
        id: "new-story-audio",
        pageUrl: history.location.pathname,
        loop: true,
      })
    );
    setTimeout(() => {
      onClose();
      closeMedia();
    }, +duration * 60 * 1000);
  };
  const editStory = (...args: any) => {
    setStoryId(args[0]);
    setStoryName(args[1]);
    setImageURL(args[2]);
    setDurationState(args[3]);
    setEdit(true);
    onOpen();
  };

  return (
    <Box>
      <>
        {data?.map((story, idx: any) => {
          return (
            <ItemCard
              resort={() =>
                handleViewModal(
                  story.id,
                  story.name,
                  story.imageUrl,
                  story.duration
                )
              }
              key={story.id}
              mb={5}
              onClick={() =>
                handleViewModal(
                  story.id,
                  story.name,
                  story.imageUrl,
                  story.duration
                )
              }
              edit={() =>
                editStory(story.id, story.name, story.imageUrl, story.duration)
              }
              remove={() => {
                if (story.id) {
                  deleteStoryMutation(story.id);
                }
              }}
            >
              {story.name}
            </ItemCard>
          );
        })}
      </>
      <ViewStoryModal
        edit={edit}
        storyId={storyId}
        isOpen={isOpen}
        storyName={storyName}
        duration={durationState}
        modalClose={() => {
          onClose();
          closeMedia();
        }}
        storyImg={imageURL}
      />
    </Box>
  );
};

export default StoryItems;
