import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import {
  AspectRatio,
  Box,
  Center,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Progress,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import IconButtons from "@material-ui/core/IconButton";
import * as mutation from "src/api/prepration/focusPoster/mutation";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getOne } from "src/api/prepration/focusPoster/query";
import { BASE_MEDIA, uploadFile } from "src/api/index";
import { FormattedMessage, useIntl } from "react-intl";
import generatePdfDocument from "./downloadPdf";
import UnsplashModal from "src/views/Prepration/FocusPoster/unsplashPlugin/UnsplashModal";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useSelector } from "src/store";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function ImageBox({
  cardId,
  selectedCard,
  titleColor,
  titleSize,
  onChange,
  reload,
  vAlign,
  hAlign,
  canEdit,
}: any) {
  const client = useQueryClient();

  const { data: card } = useQuery(...getOne(cardId));

  const { locale } = useIntl();
  const unsplashModalDisclosure = useDisclosure();
  const [value, setValue] = useState("");
  const [edited, isEdited] = useState(false);
  const [coverProgress, setCoverProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const { formatMessage } = useIntl();

  const toast = useToast();

  //current user
  const user = useSelector((state) => state.auth.user);

  const printRef = useRef(null);

  useEffect(() => {
    setValue(card ? card?.name : "");
  }, [card]);

  const handleEditableChange = (value: any) => {
    setValue(value);
    onChange(value, selectedCard);
  };

  //function to validate file types
  const validateType = (file: File) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    return validTypes.includes(file.type);
  };

  function handleChangeBackground(event: any) {
    if (event.target.files.length > 0) {
      const file = event?.target?.files[0];
      const valid = validateType(file);
      if (!valid) {
        toast({
          title: formatMessage({ id: "upload.unsupported" }),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      if (!file) return;
      const succCb = (url: string) => {
        const finalUrl = url.startsWith("media/")
          ? `${BASE_MEDIA}/${url}`
          : url;
        editMutation({ coverUrl: finalUrl, id: card?.id });
        setCoverProgress(0);
        if (reload) reload();
        isEdited(!edited);
      };
      uploadFile(file, setCoverProgress, succCb, undefined, user?.accessToken);
    }
  }

  const setCoverURL = (url) => {
    editMutation({ coverUrl: url, id: card?.id });
  };

  useEffect(() => {
    if (!downloading) return;
    setTimeout(() => {
      const anchor = document.querySelector(
        "a.op-download"
      ) as HTMLAnchorElement;
      anchor?.click();
    }, 1000);
  }, [downloading]);

  useEffect(() => {
    document.querySelector("#list-op-container")?.scrollBy(0, 999);
  }, [card?.goals]);

  const { mutateAsync: editMutation } = useMutation(mutation.edit, {
    onSuccess: (res) => client.invalidateQueries("poster"),
  });

  const generatePdfDocument = async () => {
    const element = printRef.current as HTMLElement | null;

    if (!element) {
      console.error(
        "The printRef element is null. Make sure the component has rendered."
      );
      return;
    }

    // Wait for background image to load
    const backgroundImage = new Image();
    backgroundImage.src = window
      .getComputedStyle(element)
      .backgroundImage.slice(5, -2);
    await new Promise((resolve) => {
      backgroundImage.onload = resolve;
    });

    // Temporarily hide the buttons or any elements you don't want to include in the PDF
    const buttons = element.querySelector(".buttons") as HTMLElement | null;
    if (buttons) buttons.style.display = "none";

    // Store the original border-radius and set it to 0
    const originalBorderRadius = element.style.borderRadius;
    element.style.borderRadius = "0";

    try {
      // Capture the component using html2canvas with transparent background
      const canvas = await html2canvas(element, {
        useCORS: true,
        backgroundColor: null, // No white background
      });

      // Get the dimensions of the captured element
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Initialize jsPDF in landscape mode with a custom size that matches the component's dimensions
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [imgWidth, imgHeight - 1],
      });

      // Add the image to the PDF (0,0 starting point and full width/height)
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      // Save the generated PDF
      pdf.save("download.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
    } finally {
      // Restore the original border-radius
      element.style.borderRadius = originalBorderRadius;

      // Show the buttons again after the PDF is generated
      if (buttons) buttons.style.display = "flex";
    }
  };

  return card ? (
    <AspectRatio width="100%" ratio={1.4142 / 1} gridArea="1/1/1/1">
      <Box
        backgroundImage={
          card.coverUrl?.includes("http")
            ? card.coverUrl
            : `url(${BASE_MEDIA}/${card.coverUrl})`
        }
        borderRadius="10px"
        backgroundRepeat="no-repeat"
        objectFit={"contain"}
        backgroundSize="cover"
        ref={printRef}
      >
        <Box
          elevation={4}
          style={{
            background: "rgba(0, 0, 0, .0)",
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            borderRadius: "10px",
            position: "relative",
            display: "flex",
            alignItems: card.valign || vAlign,
          }}
        >
          {coverProgress > 0 && (
            <Box padding="20px 10px">
              <Progress value={coverProgress} borderRadius="20px" />
            </Box>
          )}
          <Editable
            color={titleColor || card.nameColor}
            fontFamily="Calibri (Body)"
            variant="unstyled"
            bg="transparent"
            fontSize={`${
              (titleSize || card.nameSize || 16) * window.innerWidth * 0.0005
            }px`}
            style={{
              fontFamily: "Droid",
              position: "relative",
              textAlign: card.halign || hAlign,
            }}
            defaultValue={value}
            onChange={handleEditableChange}
            onSubmit={(e) => {
              isEdited(!edited);
              editMutation({ name: value, id: card.id });
            }}
            placeholder={value}
            w={"100%"}
          >
            <EditablePreview />
            <EditableInput />
          </Editable>
        </Box>
        <HStack
          className="buttons"
          style={{
            position: "absolute",
            margin: "0 auto",
            width: "inherit",
            display: coverProgress ? "none" : "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bottom: "5px",
          }}
        >
          <VStack
            style={{
              position: "relative",
            }}
          >
            <Box
              display="flex"
              p={["8px", "12px", "12px"]}
              flexDir="column"
              alignItems="center"
              cursor="pointer"
              style={{
                backgroundColor: "rgb(180, 180, 180, 0.2)",
                borderRadius: 10,
                marginInline: "10px",
                marginBottom: "-10px",
              }}
              onClick={() => {
                setDownloading(true);
                // generatePdfDocument(card, locale, vAlign, hAlign);
                generatePdfDocument();
              }}
            >
              <PictureAsPdfIcon
                style={{
                  color: "#141414",
                }}
              />
              <Text
                display={["none", "none", "block"]}
                style={{
                  color: "#141414",
                  fontSize: "12px",
                  textAlign: "center",
                }}
                fontFamily="Calibri (Body)"
              >
                <FormattedMessage id="focus.pdf" />
              </Text>
            </Box>
          </VStack>
          {canEdit ? (
            <HStack flexDir={"row-reverse"}>
              <VStack
                style={{
                  position: "relative",
                }}
              >
                <input
                  type="file"
                  onChange={handleChangeBackground}
                  id="uploadd"
                  accept="image/*"
                  style={{ display: "none" }}
                />
                <label htmlFor="uploadd">
                  <IconButtons
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{
                      backgroundColor: "rgb(180, 180, 180, 0.2)",
                      borderRadius: 10,
                      marginInline: "10px",
                    }}
                  >
                    <VStack>
                      <AddPhotoAlternateIcon
                        style={{
                          color: "#141414",
                        }}
                      />
                      <Text
                        display={["none", "none", "block"]}
                        style={{
                          color: "#141414",
                          fontSize: "12px",
                        }}
                        fontFamily="Calibri (Body)"
                      >
                        <FormattedMessage id="focus.wallpaper" />
                      </Text>
                    </VStack>
                  </IconButtons>
                </label>
                <label htmlFor="background" />
              </VStack>
              <VStack
                style={{
                  position: "relative",
                }}
              >
                <span />
                <label>
                  <IconButtons
                    color="primary"
                    component="span"
                    style={{
                      backgroundColor: "rgb(180, 180, 180, 0.2)",
                      borderRadius: 10,
                      marginInline: "10px",
                    }}
                    onClick={() => {
                      unsplashModalDisclosure.onOpen();
                    }}
                  >
                    <VStack>
                      <ImageSearchIcon
                        style={{
                          color: "#141414",
                          // backgroundColor:"red"
                        }}
                      />
                      <Text
                        display={["none", "none", "block"]}
                        style={{
                          color: "#141414",
                          fontSize: "12px",
                        }}
                        fontFamily="Calibri (Body)"
                      >
                        <FormattedMessage id="ChoosefromUnsplash" />
                      </Text>
                    </VStack>
                  </IconButtons>
                </label>
                <label htmlFor="background" />
              </VStack>
            </HStack>
          ) : null}
        </HStack>
        <UnsplashModal
          isOpen={unsplashModalDisclosure.isOpen}
          onClose={unsplashModalDisclosure.onClose}
          setCoverURL={setCoverURL}
        />
      </Box>
    </AspectRatio>
  ) : (
    <Center>
      <Spinner />
    </Center>
  );
}

export default ImageBox;
