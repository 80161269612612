import React, { useState, useMemo, useCallback } from "react";
import BaseView from "../BaseView";
import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  VStack,
  IconButton,
} from "@chakra-ui/react";

import { Solfeggio } from "src/types";
import { useIntl } from "react-intl";
import { useGetSolfeggio } from "src/newApi/hooks";
import { useDispatch } from "src/store";
import { playMedia, playPlaylist } from "src/store/media";
import { MdPlaylistAdd } from "react-icons/md";
import { BsFillPlayFill } from "react-icons/bs";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import { SOLFEGGIO_ROUTE } from "src/routes";
import { useHistory } from "react-router-dom";

const stylesArray = [
  { top: "0", right: "50%", transform: "translate(50%, -50%)" },
  { top: "0", right: "0", transform: "translate(-100%, 50%)" },
  { top: "50%", right: "0", transform: "translate(10%, -100%)" },
  { bottom: "0", right: "0", transform: "translate(-20%, -150%)" },
  { bottom: "0", right: "0", transform: "translate(-210%, 10%)" },
  { bottom: "0", left: "0", transform: "translate(200%, 5%)" },
  { bottom: "0", left: "0", transform: "translate(10%, -150%)" },
  { bottom: "0", left: "0", transform: "translate(-20%, -390%)" },
  { top: "0", left: "0", transform: "translate(100%, 50%)" },
];
const arabicStylesArray = [
  { top: "0", right: "50%", transform: "translate(50%, -50%)" },
  { top: "0", left: "25%", transform: "translate(-100%, 50%)" },
  { top: "50%", left: "0", transform: "translate(10%, -100%)" },
  { bottom: "0", left: "5%", transform: "translate(-20%, -150%)" },
  { bottom: "0", left: "55%", transform: "translate(-210%, 10%)" },
  { bottom: "0", right: "55%", transform: "translate(200%, 5%)" },
  { bottom: "0", right: "0", transform: "translate(10%, -150%)" },
  { bottom: "0", right: "-8%", transform: "translate(-20%, -390%)" },
  { top: "0", right: "25%", transform: "translate(100%, 50%)" },
];

const Solf = () => {
  const { formatMessage, locale } = useIntl();
  const history = useHistory();
  const { isLoading, error, data: solfeggios } = useGetSolfeggio();
  const styles = useMemo(() => {
    return locale === "ar" ? arabicStylesArray : stylesArray;
  }, [locale]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSolfegioOpen,
    onOpen: onSolfegioOpen,
    onClose: onSolfegioClose,
  } = useDisclosure();
  const {
    isOpen: isAddToPlaylistOpen,
    onOpen: onAddToPlaylistOpen,
    onClose: onAddToPlaylistClose,
  } = useDisclosure();
  const [currentSolfeggio, setCurrentSolfeggio] = useState<Solfeggio>();
  const dispatch = useDispatch();

  const playAll = useCallback(() => {
    if (!solfeggios) return;
    const tracks = solfeggios.map((sol) => {
      return {
        ...sol,
        audioUrl: sol.translations?.find((t) => t.locale === locale)?.audioUrl,
        entityName: "solfeggio",
        entityId: sol.id,
        gif: sol?.translations?.find((t) => t.locale === locale)?.gifUrl!,
      };
    });
    console.log("tracks", tracks);
    dispatch(
      playPlaylist({
        playlist: tracks,
        playlistId: Math.random() * 10,
        isStatic: true,
        pageUrl: history.location.pathname,
      })
    );
  }, [dispatch, locale, solfeggios, history.location.pathname]);

  const playSolfeggio = useCallback(
    (solfeggio: Solfeggio, type: "audio" | "video") => {
      const translation = solfeggio.translations?.find(
        (t) => t.locale === locale
      );
      if (translation) {
        dispatch(
          playMedia({
            frBtn: true,
            name: `solfeggio-${solfeggio.frequency!} Hz`,
            id: `slf-${solfeggio.id}`,
            type: type,
            source:
              type === "audio" ? translation.audioUrl! : translation.videoUrl!,
            loop: type === "audio",
            disableSeeking: true,
            gif: type === "audio" ? translation.gifUrl! : undefined,
            pageUrl: history.location.pathname,
          })
        );
      }
      onSolfegioClose();
    },
    [dispatch, locale, history.location.pathname, onSolfegioClose]
  );

  if (isLoading)
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  if (error || solfeggios === undefined) return <div>error fetching data</div>;
  return (
    <>
      <VStack
        display="flex"
        flexDir="column"
        alignItems="center"
        pt={["100", "50", "10"]}
      >
        <Button style={{ transform: "translate(0%, -210%)" }} onClick={onOpen}>
          {formatMessage({ id: "description-solfeggio" })}
        </Button>
        <Box
          id="solfeg"
          width={["300px", "400px", "400px", "500px"]}
          height={["300px", "400px", "400px", "500px"]}
          marginTop={"50px"}
          position="relative"
          marginBottom="100px"
        >
          {/* <Button style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }} onClick={playAll}>
            {formatMessage({ id: "description-solfeggio" })}
          </Button> */}
          <Box
            // style={{ width: "100%", height: "100%", transform: "scale(1.3)" }}
            style={{
              width: "100px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {/* <Button onClick={playAll} variant="unstyled" color="brand.900" >
              <BsFillPlayFill fontSize={100} />
            </Button> */}
            <IconButton
              // className="remove-on-print"
              onClick={playAll}
              height="130px"
              // marginEnd={"10px"}
              variant="unstyled"
              size="lg"
              // border="2px solid #E2E8F0"
              aria-label="playall"
              color="brand.900"
              icon={<BsFillPlayFill fontSize={100} />}
            />
            <Button
              mt="-5"
              variant={"unstyled"}
              onClick={playAll}
              fontWeight={"bold"}
            >
              {formatMessage({ id: "playall" })}
            </Button>
          </Box>
          <img
            alt="solfeggio types"
            src={require(`src/assets/images/solfeggio${locale}.jpeg`).default}
            style={{ width: "100%", height: "100%", transform: "scale(1.3)" }}
          />
          {/* Solfeggio Map */}
          {solfeggios?.map((sol, index) => (
            <Box
              key={sol.id! + index}
              as="button"
              cursor="pointer"
              onClick={() => {
                setCurrentSolfeggio(sol);
                onSolfegioOpen();
              }}
              width={["40px", "55px", "55px", "70px"]}
              height={["40px", "55px", "55px", "70px"]}
              borderRadius="50%"
              position="absolute"
              {...styles[index]}
            />
          ))}
        </Box>
      </VStack>
      <Modal
        isOpen={isSolfegioOpen}
        onClose={onSolfegioClose}
        isCentered
        allowPinchZoom
      >
        <ModalOverlay />
        <ModalContent paddingBottom="20px" width={"fit-content"}>
          <ModalHeader>
            <ModalCloseButton color="black" bg="white" />
          </ModalHeader>
          <ModalBody>
            <IconButton
              className="remove-on-print"
              onClick={onAddToPlaylistOpen}
              marginEnd={"10px"}
              size="lg"
              border="2px solid #E2E8F0"
              aria-label="add"
              icon={<MdPlaylistAdd />}
            />
            <Button
              width={"fit-content"}
              onClick={() =>
                currentSolfeggio && playSolfeggio(currentSolfeggio, "audio")
              }
            >
              {formatMessage({
                id: "audio",
              })}
            </Button>
            <Button
              marginStart={"10px"}
              width={"fit-content"}
              onClick={() =>
                currentSolfeggio && playSolfeggio(currentSolfeggio, "video")
              }
            >
              {formatMessage({
                id: "video",
              })}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        allowPinchZoom
        size="full"
        blockScrollOnMount={false}
      >
        <ModalContent>
          <ModalHeader />
          <ModalBody mt="50">
            <ModalCloseButton color="black" bg="white" mt="50" />
            <Box
              pt="5"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Solfeggio description"
                src={
                  require(`src/assets/images/Solfeggio-description-${locale}.png`)
                    .default
                }
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <AddToPlaylistModal
        isOpen={isAddToPlaylistOpen}
        onClose={onAddToPlaylistClose}
        track={makeTrackFromSolfeggio(currentSolfeggio, locale)}
        entityName={ENTITY_NAME}
      />
    </>
  );
};
const SolfContainer = () => {
  return (
    <BaseView route={SOLFEGGIO_ROUTE}>
      <Solf />
    </BaseView>
  );
};

export default React.memo(SolfContainer);

const ENTITY_NAME = "solfeggio";

const makeTrackFromSolfeggio = (sol: Solfeggio | undefined, locale: string) => {
  if (!sol) return;
  const track = sol.translations?.find((track) => track.locale === locale);
  return {
    frequency: sol.frequency,
    ...track,
    entityName: ENTITY_NAME,
    entityId: sol!.id,
  };
};
