import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import { FormattedMessage } from "react-intl";
import Card from "src/components/Card";
import PrimaryButton from "src/components/PrimaryButton";
import { Vision, Wish } from "src/types";
import { ReturnUseWishes } from "./useWishes";
import WishItem from "./WishItem";
import { scrollBarStyles } from "src/layout/ScrollBar";

type WishesStepOneProps = ReturnUseWishes & {
  submitTopTen: (wishes: [Wish]) => Promise<Vision>;
};
const WishesStepTwo: React.FC<
  WishesStepOneProps & { openPreview: (modalId: number) => void }
> = ({ top10, top20, returnTo20, addTo10, submitTopTen, openPreview }) => {
  return (
    <Box flex="1">
      <Text
        marginStart="30px"
        d="inline-block"
        color="brand.900"
        fontSize="18px"
        fontWeight="bold"
        fontFamily="Calibri (Body)"
        mt={"22px"}
        mb={"30px"}
      >
        <FormattedMessage
          id="vision.step2.create.title"
          defaultMessage="قم باختيار أهم 10 أمنيات"
        />
      </Text>
      <Box d="flex" flexDir="row" flex="1" className="here">
        <Card
          me={6}
          pt={"16px"}
          ps={"46px"}
          pe={"30px"}
          d="flex"
          flexDir="column"
          h="600px"
          // flex="1"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="brand.900"
            fontFamily="Calibri (Body)"
            lineHeight="36px"
            mb="16px"
          >
            <FormattedMessage
              id="vision.wishes.step2.card1.title"
              defaultMessage="أهم 20 أمنية"
            />
          </Text>
          <Box overflowY="auto" flex="1" css={scrollBarStyles}>
            <Box>
              {top20.map((w, idx) => (
                <WishItem
                  key={w.id}
                  done={top10.length === 10}
                  addHandler={() => {
                    top10.length !== 10 && addTo10(idx);
                  }}
                  mb={2}
                >
                  {w.name}
                </WishItem>
              ))}
            </Box>
          </Box>
        </Card>
        <Card
          me={6}
          pt={"16px"}
          ps={"46px"}
          pe={"30px"}
          d="flex"
          flexDir="column"
          h="600px"
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="brand.900"
            fontFamily="Calibri (Body)"
            lineHeight="36px"
            mb="16px"
          >
            <FormattedMessage
              id="vision.wishes.step2.card2.title"
              defaultMessage="أهم 10 أمنيات"
            />
          </Text>
          <Box overflowY="auto" flex="1" css={scrollBarStyles}>
            <Box>
              {top10.map((w, idx) => (
                <WishItem
                  key={w.id}
                  removeHandler={() => {
                    returnTo20(idx);
                  }}
                  mb={2}
                >
                  {w.name}
                </WishItem>
              ))}
            </Box>
          </Box>
        </Card>
      </Box>
      <Center mt={8} mb={10}>
        <PrimaryButton
          disabled={top10.length !== 10}
          onClick={async () => {
            const vision: Vision = await submitTopTen(top10 as [Wish]);
            openPreview(vision.id ? vision.id : 0);
          }}
        >
          <FormattedMessage id="next" defaultMessage="التالي" />
        </PrimaryButton>
      </Center>
    </Box>
  );
};
export default WishesStepTwo;
