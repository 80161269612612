import { DownloadIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import useLocale from "src/providers/useLocale";
import { FiltersContext } from "./FiltersContext";
import { useSelector } from "src/store";
import usePlanApi from "../usePlanApi";
import * as XLSX from 'xlsx-js-style';

const ExcelPlan = () => {
  const planId = +window.location.pathname.split("/")[4] || 0;
  const { planQuery } = usePlanApi(planId);
  const { endDate, excutor, startDate } = useContext(FiltersContext);
  const user = useSelector((state) => state.auth.user);
  const { formatMessage } = useIntl();
  const [locale] = useLocale();
  const isRTL = locale === "ar";

  const safeFormatMessage = (id: string, defaultMessage: string = "") => {
    try {
      return formatMessage({ id });
    } catch {
      return defaultMessage || id;
    }
  };

  const toArabicNumbers = (str: string | number): string => {
    if (!str) return '';
    return String(str).replace(/[0-9]/g, d => '٠١٢٣٤٥٦٧٨٩'[d]);
  };

  const formatDate = (date: string | Date): string => {
    if (!date) return '';
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    const dateStr = `${day}/${month}/${year}`;
    return isRTL ? toArabicNumbers(dateStr) : dateStr;
  };

  const getDefaultStyle = (isHeader = false) => ({
    font: { 
      name: isRTL ? 'Arial' : 'Calibri',
      sz: isHeader ? 12 : 11,
      bold: isHeader,
    },
    alignment: {
      horizontal: isRTL ? 'right' : 'left',
      vertical: 'center',
      wrapText: true,
      reading_order: isRTL ? 2 : 1,
    },
    border: isHeader ? {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    } : null,
  });

  const createExcelData = () => {
    const data: any[] = [];

    // Title styles
    const titleStyle = {
      ...getDefaultStyle(true),
      font: { sz: 16, bold: true },
      alignment: { horizontal: 'center', reading_order: isRTL ? 2 : 1 },
    };

    // Add title and metadata
    data.push(['']); // Empty row for spacing
    data.push([{ v: planQuery.data?.name || '', s: titleStyle }]);
    data.push([{ v: user?.name || '', s: { ...titleStyle, font: { sz: 14, bold: false } } }]);
    data.push(['']); // Empty row for spacing
    data.push(['']); // Empty row for spacing

    // Process aspects and goals
    planQuery.data?.aspects?.forEach((aspect, aspectIndex) => {
      // Add aspect header
      const aspectHeader = `${safeFormatMessage("aspect", "Aspect")} ${isRTL ? toArabicNumbers(aspectIndex + 1) : aspectIndex + 1}: ${aspect.name}`;
      const aspectRow = Array(8).fill({ v: '', s: getDefaultStyle() });
      aspectRow[isRTL ? 7 : 0] = {
        v: aspectHeader,
        s: {
          ...getDefaultStyle(true),
          fill: { fgColor: { rgb: "CCCCCC" } },
          alignment: { horizontal: isRTL ? 'right' : 'left', reading_order: isRTL ? 2 : 1 },
        }
      };
      data.push(aspectRow);
      data.push(Array(8).fill({ v: '', s: getDefaultStyle() })); // Empty row

      aspect.goals?.forEach((goal, goalIndex) => {
        // Add goal header
        const goalHeader = `${safeFormatMessage("goal", "Goal")} ${isRTL ? toArabicNumbers(goalIndex + 1) : goalIndex + 1}: ${goal.name}`;
        const goalRow = Array(8).fill({ v: '', s: getDefaultStyle() });
        goalRow[isRTL ? 7 : 0] = {
          v: goalHeader,
          s: {
            ...getDefaultStyle(true),
            fill: { fgColor: { rgb: "E8E8E8" } },
            alignment: { horizontal: isRTL ? 'right' : 'left', reading_order: isRTL ? 2 : 1 },
          }
        };
        data.push(goalRow);
        data.push(Array(8).fill({ v: '', s: getDefaultStyle() })); // Empty row

        if (goal.actions?.length) {
          // Table headers
          let headers = [
            safeFormatMessage("measure", "Measure"),
            safeFormatMessage("plan.goal.action.create.quantity", "Quantity"),
            safeFormatMessage("weight", "Weight"),
            safeFormatMessage("plan.goal.action.create.excutor", "Executor"),
            safeFormatMessage("action.startdate", "Start Date"),
            safeFormatMessage("action.endDate", "End Date"),
            safeFormatMessage("done", "Done"),
            safeFormatMessage("status", "Status"),
          ];

          if (isRTL) headers.reverse();

          data.push(headers.map(header => ({
            v: header,
            s: {
              ...getDefaultStyle(true),
              fill: { fgColor: { rgb: "F2F2F2" } },
              alignment: { horizontal: 'center', reading_order: isRTL ? 2 : 1 },
            }
          })));

          // Filter and add actions
          const filteredActions = goal.actions.filter(action => {
            if (!excutor && !startDate && !endDate) return true;
            
            const conditions = [];
            if (excutor) {
              conditions.push(action.executor.trim().toLowerCase().includes(excutor.trim().toLowerCase()));
            }
            if (startDate) {
              conditions.push(formatDate(action.startDate) === formatDate(startDate));
            }
            if (endDate) {
              conditions.push(formatDate(action.endDate) === formatDate(endDate));
            }
            return conditions.length ? conditions.every(c => c) : true;
          });

          filteredActions.forEach(action => {
            let row = [
              action.name,
              isRTL ? toArabicNumbers(action.quantity) : action.quantity,
              action.weight ? (isRTL ? toArabicNumbers(action.weight) : action.weight) : '-',
              action.executor,
              formatDate(action.startDate),
              formatDate(action.endDate),
              isRTL ? toArabicNumbers(action.counter) : action.counter,
              action.complete
                ? safeFormatMessage("completed", "Completed")
                : safeFormatMessage("notCompleted", "Not Completed")
            ];

            if (isRTL) row.reverse();

            data.push(row.map(cell => ({
              v: cell,
              s: {
                ...getDefaultStyle(),
                fill: {
                  fgColor: {
                    rgb: action.complete ? "E8F5E9" : 
                         new Date() > new Date(action.endDate) ? "FFEBEE" : "FFFFFF"
                  }
                },
                alignment: { horizontal: isRTL ? 'right' : 'left', reading_order: isRTL ? 2 : 1 },
              }
            })));
          });
        }
        data.push(Array(8).fill({ v: '', s: getDefaultStyle() })); // Empty row
      });
      data.push(Array(8).fill({ v: '', s: getDefaultStyle() })); // Empty row
    });

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Set column widths
    ws['!cols'] = Array(8).fill({ width: isRTL ? 20 : 15 });

    // Add RTL worksheet properties
    ws['!dir'] = isRTL ? 'rtl' : 'ltr';
    if (isRTL) {
      // These properties force Excel to display the sheet in RTL mode
      ws['!worksheet'] = {
        ...ws['!worksheet'],
        rightToLeft: true,
      };
      // Add custom XML part for RTL layout
      ws['!xml'] = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
        <worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" 
                  xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">
          <sheetPr>
            <outlinePr summaryBelow="0"/>
          </sheetPr>
          <sheetFormatPr defaultRowHeight="15"/>
          <sheetViews>
            <sheetView rightToLeft="1" workbookViewId="0"/>
          </sheetViews>
        </worksheet>`;
    }

    return ws;
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    
    // Set workbook properties for RTL
    if (isRTL) {
      wb.Workbook = {
        ...wb.Workbook,
        Views: [{
          RTL: true,
        }]
      };
    }

    const ws = createExcelData();
    
    XLSX.utils.book_append_sheet(
      wb, 
      ws, 
      planQuery.data?.name || safeFormatMessage("plan", "Plan")
    );

    XLSX.writeFile(
      wb, 
      `${planQuery.data?.name || safeFormatMessage("plan", "Plan")}.xlsx`
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="0"
      padding="0"
      alignItems="center"
      py={1}
      position="relative"
      right="3.5"
    >
      <IconButton
        bg="#e6e6e6"
        fontSize="30px"
        width="70px"
        height="70px"
        aria-label={safeFormatMessage("downloadExcel", "Download Excel")}
        color="brand.800"
        icon={<DownloadIcon />}
        onClick={exportToExcel}
      />
      <Text
        marginTop="10px"
        fontSize="18px"
        color="gray.900"
        maxWidth="100px"
        textAlign="center"
      >
        {safeFormatMessage("downloadExcel", "Download Excel")}
      </Text>
    </Box>
  );
};

export default ExcelPlan;