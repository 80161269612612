import React from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Tag,
} from "@chakra-ui/react";
import { MinusIcon, CloseIcon } from "@chakra-ui/icons";
import { FaWindowMaximize } from "react-icons/fa";
import MediaPlayer from "src/components/MediaPlayer";
import { useSelector, useDispatch } from "src/store";
import { useIntl } from "react-intl";
import { stopMedia, toggleVisible } from "src/store/media";
import PlaylistPlayer from "src/components/MediaPlayer/PlaylistPlayer/PlaylistPlayer";
//Context for playing media in the background

const MediaBox: React.FC<{ children: any }> = ({ children }) => {
  const color = useColorModeValue("white", "gray.500");
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const setVisible = (v: boolean) => dispatch(toggleVisible({ value: v }));
  const closeMedia = () => dispatch(stopMedia());
  const user = useSelector((state) => state.auth.user);
  const {
    type,
    id,
    current,
    currentGif,
    visible,
    loopMedia,
    disableSeeking,
    name,
    entityName,
  } = useSelector((state) => ({
    type: state.media.type,
    id: state.media.id,
    current: state.media.current,
    currentGif: state.media.currentGif,
    visible: state.media.visible,
    isPlay: state.media.playing,
    loopMedia: state.media.loop,
    disableSeeking: state.media.disableSeeking,
    name: state.media.name,
    entityName: state.media.entityName,
  }));
  const showModal =
    type === "video" ||
    ("" + id).includes("slf") ||
    ("" + id).includes("chakra") ||
    type === "playlistAudio";

  const isMaximized = (visible && current && showModal) as boolean;

  if (!user) return children;
  return (
    <>
      <div
        style={{
          // display: visible && current && type !== "audio" ? "block" : "none",
          display: current && showModal ? "block" : "none",
          // visibility: isMaximized ? "visible" : "hidden",
          // display: "block"
        }}
      >
        <Box
          id="video-parent"
          position="fixed"
          top={isMaximized ? "50%" : "100%"}
          left="50%"
          transform={
            isMaximized ? "translate(-50%, -50%)" : "translate(-50%, -100%)"
          }
          backgroundColor={color}
          padding={["10px", "5px", "15px"]}
          // width={{ base: "100%", sm: "700px", md: "500px" }}
          maxHeight={"100vh"}
          borderRadius="10px"
          boxShadow="0 0 10px 2px silver"
          zIndex="100"
          maxWidth="100vw"
          width={isMaximized ? "" : "100%"}
          minWidth="200px"
        >
          <Flex flexDir="column">
            <Box ml="3" d="flex" justifyContent={"flex-end"}>
              {visible ? (
                <MinusIcon
                  width="15px"
                  height="15px"
                  margin={"0 10px"}
                  cursor="pointer"
                  onClick={() => setVisible(false)}
                />
              ) : (
                <FaWindowMaximize
                  width="10px"
                  height="10px"
                  cursor="pointer"
                  onClick={() => setVisible(true)}
                />
              )}
              <CloseIcon
                width="15px"
                height="15px"
                margin={"0 10px"}
                cursor="pointer"
                onClick={() => closeMedia()}
              />
            </Box>
            {currentGif && currentGif !== "" && isMaximized ? (
              currentGif.endsWith(".gif") ? (
                <Image
                  src={currentGif}
                  sx={{
                    pointerEvents: "none",
                    maxHeight: "400px",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <MediaPlayer
                  isMaximized={isMaximized}
                  src={currentGif}
                  loop={true}
                  noControls={true}
                />
              )
            ) : null}
            <Box
              d="flex"
              flexWrap={"wrap"}
              flexDir={isMaximized ? "column" : "row"}
              mx="auto"
              alignItems={"center"}
              justifyContent="space-evenly"
              width="100%"
            >
              <Box
                alignItems="center"
                justifyContent="center"
                marginTop={isMaximized ? "10px" : 0}
                mr="auto"
                d="flex"
                flexWrap={isMaximized ? "wrap" : "nowrap"}
              >
                <Text>{name}</Text>
                {entityName ? (
                  <Tag
                    size={"md"}
                    borderRadius="full"
                    variant="solid"
                    mx="3"
                    color="white"
                    textTransform={"capitalize"}
                    minWidth="fit-content"
                  >
                    {formatMessage({
                      id: entityName.replace(/([a-z])([A-Z])/g, "$1 $2"),
                    })}
                  </Tag>
                ) : null}
              </Box>
              <MediaPlayer
                disableSeeking={disableSeeking}
                src={current}
                loop={loopMedia}
                key={current}
                isMaximized={isMaximized}
                styles={{
                  minWidth: "185px",
                }}
              />
            </Box>
          </Flex>
        </Box>
        <PlaylistPlayer />
      </div>
      {children}
    </>
  );
};

export default MediaBox;
