import { HStack, VStack, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Section from "./Section";
import DarkHeader from "src/layout/DarkHeader";
import {
  AFFIRMATION_ROUTE,
  COMMUNICATION_ROUTE,
  FREQUENCY_ROUTE,
  GATE_ROUTE,
  PREPARATION_ROUTE,
} from "src/routes";
import {
  PreparationSection,
  AffirmationsSection,
  CommunicationSection,
  FrequenciesSection,
  GatesSection,
} from "./Sections";
import Calibration from "../Affirmation/Calibration";
import { useAppContext } from "../../Providers";
import { useSelector } from "src/store";
import useIsDev from "../../helpers/useIsDev";
import { Redirect } from "react-router-dom";
import { get } from "src/api";

const notification = {
  preparationSection: {
    salplan: { count: 10, title: "sonemsdfk", body: "" },
  },
};

const Portal: React.FC<{}> = () => {
  const [loaded, loadVideo] = React.useReducer(() => true, false);
  const [notifications, setNotifications] = useState<any>({});
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    loadVideo();
  }, []);
  const context = useAppContext();
  const isDev = useIsDev();
  useEffect(() => {
    context.background?.set(
      'url("https://saladmin.smartsway.com:3000/portal-background.png")'
    );
    get("/shared/notification/all").then((res: any) => {
      if (res) {
        setNotifications(res);
      }
    });
    return () => {
      context.background?.set("");
    };
  }, [context.background]);
  // if (!user && !isDev) {
  //   window.location.href = "https://sal125.smartsway.com/landing";
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: "white",
  //         position: "absolute",
  //         top: 0,
  //         right: 0,
  //         bottom: 0,
  //         left: 0,
  //       }}
  //     />
  //   );
  // }
  if (!user) {
    return <Redirect to={"/"} />;
  }
  if (user?.pendingOrder && user?.subscriptionStatus === "pending")
    return <Redirect to={"/subscription"} />;

  // if (user?.subscriptionStatus !== "approved" || user?.pendingOrder) {
  //   // that means the user have no
  //   return <Redirect to={"/subscription"} />;
  // }
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          minWidth: "100%",
          minHeight: "100vh",
          backgroundImage: context.background.get,
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // background: "rgba(0, 0, 0, .7)",
          zIndex: -100,
        }}
      />
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          minWidth: "100%",
          minHeight: "100vh",
          zIndex: -50,
          opacity: 0.3,
        }}
      >
        {loaded ? (
          <video
            id="backgroundVideo"
            preload="none"
            onContextMenu={(e) => e.preventDefault()}
            controlsList="nodownload"
            src="https://saladmin.smartsway.com:3000/media/bg.MOV"
            autoPlay
            muted
            playsInline
            loop
            style={{
              position: "fixed",
              objectFit: "cover",
              minWidth: "100%",
              minHeight: "100%",
              top: 0,
              left: 0,
              pointerEvents: "none",
            }}
            controls
          />
        ) : null}
      </div>
      <Box backgroundSize={"100%"} paddingBottom="50px">
        <DarkHeader />
        <HStack
          flexDirection={["column", "column", "column", "column", "row"]}
          alignItems={["center", "center", "center", "center", "flex-start"]}
          style={{
            justifyContent: "center",
            margin: "0 auto",
            marginTop: "1em",
          }}
        >
          <Section
            title="prepration"
            slug="Prepration"
            categories={PreparationSection}
            notifications={notifications}
            mainRoute={PREPARATION_ROUTE}
          />
          <VStack>
            <Section
              title="affirmation"
              slug="Affirmation"
              notifications={notifications}
              categories={AffirmationsSection}
              mainRoute={AFFIRMATION_ROUTE}
            />
            <Section
              title="communication"
              slug="Communication"
              notifications={notifications}
              categories={CommunicationSection}
              mainRoute={COMMUNICATION_ROUTE}
            />
          </VStack>
          <VStack>
            <Section
              title="frequency"
              slug="Frequencies"
              notifications={notifications}
              categories={FrequenciesSection}
              mainRoute={FREQUENCY_ROUTE}
            />
            <Section
              title="gate"
              slug="Gates"
              notifications={notifications}
              categories={GatesSection}
              mainRoute={GATE_ROUTE}
            />
          </VStack>
        </HStack>
        <Calibration />
      </Box>
    </>
  );
};

export default Portal;
