import React, { useState, useEffect } from "react";
import {
  VStack,
  HStack,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { get, DisableSeeking } from "src/api/index";
import useLocale from "src/providers/useLocale";
import BaseView from "../BaseView";
import ReactPlayer from "react-player";

type tapResponse = {
  article: Array<{ symbol?: string; place?: string }>;
  media: Array<{ id?: number; type?: string; url?: string }>;
};

const Tapping = () => {
  //content to be displayed
  const [content, setContent] = useState<{
    imageUrl: string;
    videoUrl: string;
    content?: Array<{ symbol?: string; place?: string }>;
  }>();

  //current locale
  const [locale] = useLocale();

  useEffect(() => {
    const fetchContent = async () => {
      const response = await get<tapResponse>("/tft/tap", locale);
      if (response) {
        const responseContent = {
          content: response.article,
          imageUrl: response.media.find((obj) => obj.type === "image")?.url!,
          videoUrl: response.media.find((obj) => obj.type === "video")?.url!,
        };
        setContent(responseContent);
      }
    };
    fetchContent();
  }, [locale]);

  useEffect(() => {
    const video = document.getElementById("tap-vid") as HTMLVideoElement;
    if (!video) return;
    DisableSeeking(video);
  }, [content]);

  return (
    <BaseView>
      <VStack width="100%" paddingTop="50px">
        <HStack
          display="flex"
          flexDir={{ base: "column", xl: "row" }}
          alignItems={{ base: "center", xl: "flex-start" }}
          justifyContent={{ xl: "space-between" }}
          width={{ xl: "800px" }}
        >
          <Box
            background="#f6f7f2"
            width="300px"
            borderRadius="15px"
            boxShadow="-1px 2px 5px 1px silver"
            padding="20px 40px"
          >
            <Text
              color="#bebfba"
              fontWeight="bold"
              fontSize="18px"
              textAlign="center"
            >
              <FormattedMessage id="tapTypes" />
            </Text>
            <Table marginTop="20px">
              <Thead>
                <Tr>
                  <Th
                    padding="10px 0 20px 0"
                    textAlign="center"
                    fontSize="16px"
                  >
                    <Text display="block" minWidth="70px">
                      <FormattedMessage id="symbol" />
                    </Text>
                  </Th>
                  <Th
                    padding="10px 0 20px 0"
                    textAlign="center"
                    fontSize="16px"
                  >
                    <Text display="block">
                      <FormattedMessage id="tapPos" />
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {content?.content?.map(({ symbol, place }) => (
                  <Tr>
                    <Td textAlign="center" paddingBlock="10px" key={symbol}>
                      <Text
                        display="block"
                        key={symbol}
                        color="#bebfbb"
                        textTransform="capitalize"
                      >
                        {/* <FormattedMessage id={pos} /> */}
                        {symbol}
                      </Text>
                    </Td>
                    <Td textAlign="center" paddingBlock="10px">
                      <Text display="block" key={symbol} color="#bebfbb">
                        {/* <FormattedMessage id={`tap_${pos}`} /> */}
                        {place}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            width={{ base: "300px", md: "450px" }}
            paddingTop={{ base: "40px", xl: "0" }}
          >
            {content?.videoUrl?.includes("youtube") ||
            content?.videoUrl?.includes("youtu.be") ? (
              <ReactPlayer
                preload="none"
                className="react-player fixed-bottom"
                url={content?.videoUrl}
                controls
                width={"490px"}
                height={"275px"}
                style={{
                  borderRadius: "25px",
                  marginBottom: "0.7em",
                  overflow: "hidden",
                }}
                playsInline
              />
            ) : (
              <video
                preload="none"
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
                id="tap-vid"
                src={content?.videoUrl}
                style={{ borderRadius: "25px", margin: "0 auto .7em auto" }}
                controls
              />
            )}
          </Box>
        </HStack>
        <HStack
          display="flex"
          flexDir={{ base: "column" }}
          alignItems="center"
          paddingTop={{ base: "40px", xl: "80px" }}
          paddingBottom="80px"
        >
          <Box
            width={{ base: "100%", xl: "800px" }}
            border={content?.imageUrl ? "1px solid silver" : "none"}
          >
            <img
              src={content?.imageUrl}
              style={{ width: "100%" }}
              alt={"content"}
            />
          </Box>
        </HStack>
      </VStack>
    </BaseView>
  );
};

export default Tapping;
