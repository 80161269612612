import { useDisclosure, UseDisclosureReturn, useToast } from "@chakra-ui/react";
import React from "react";
import { Plan as PlanType } from "src/types";
import NewItemCard from "../../../../components/NewItemCard/NewItemCard";
import usePlanApi from "../usePlanApi";
import { useIntl } from "react-intl";
import { SALPLAN_ROUTE } from "src/routes";
import { useHistory } from "react-router-dom";

type PlanProps = {
  plan: PlanType | { id: number; name: string };
  editPlanDiclosure: UseDisclosureReturn;
  onEdit: () => void;
  editPlanValue?: PlanType;
  setId: (plan: PlanType) => void;
  onShare?: any;
  removeButton?: boolean;
  sharedProps?: any;
  isSharedWithMe?: boolean;
  openChangeFolderModal?: () => void;
  groupId?: string;
  shareId: number;
};

const Plan: React.FC<PlanProps> = ({
  plan,
  onEdit,
  onShare,
  removeButton,
  sharedProps,
  isSharedWithMe,
  openChangeFolderModal,
  groupId,
  shareId,
}) => {
  const { deletePlanMutation, douplicateMutation, douplicateSharedMutation } =
    usePlanApi(plan.id);
  const createAspectDisclosure = useDisclosure();
  const toast = useToast();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const deleteClickHandler = async (id: number) => {
    try {
      await deletePlanMutation(id);
      toast({
        title: formatMessage({ id: "plan.delete.success" }),
        status: "success",
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "plan.delete.failed" }),
        description: error.message,
        status: "success",
        isClosable: true,
      });
    }
  };
  const douplicateClickHandler = async (id: string) => {
    try {
      await douplicateMutation({
        planId: id,
        groupId: undefined,
      });
      // toast({
      //   title: formatMessage({ id: "plan.delete.success" }),
      //   status: "success",
      //   isClosable: true,
      // });
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "plan.delete.failed" }),
        description: error.message,
        status: "success",
        isClosable: true,
      });
    }
  };

  const douplicateSharedHandler = async (id: string) => {
    try {
      await douplicateSharedMutation({
        planId: id,
        groupId,
      });
      // toast({
      //   title: formatMessage({ id: "plan.delete.success" }),
      //   status: "success",
      //   isClosable: true,
      // });
    } catch (error: any) {
      toast({
        title: formatMessage({ id: "plan.delete.failed" }),
        description: error.message,
        status: "success",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <NewItemCard
        onCardCLick={(e) => {
          history.push(`${SALPLAN_ROUTE}/${plan.id}`, sharedProps);
        }}
        key={plan.id}
        mb={5}
        edit={() => {}}
        remove={
          removeButton
            ? () => {
                if (window.confirm(formatMessage({ id: "deleteConfirm" }))) {
                  deleteClickHandler(plan.id!);
                }
              }
            : null
        }
        isSharedWithMe={isSharedWithMe}
        douplicate={() => {
          if (isSharedWithMe) douplicateSharedHandler(`${plan.id!}`);
          else douplicateClickHandler(`${plan.id!}`);
        }}
        share={onShare}
        newItem={{
          text: formatMessage({ id: "plan.aspect.create" }),
          onClick: () => {
            createAspectDisclosure.onOpen();
          },
        }}
        openChangeFolderModal={openChangeFolderModal}
        shareId={shareId}
      >
        {plan.name}
      </NewItemCard>
    </>
  );
};

export default Plan;
