import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

import PrimaryButton from "src/components/PrimaryButton";

import { FormattedMessage, useIntl } from "react-intl";

import RatingStars from "./RatingStars";

import { post, put } from "src/api";

export interface MyRating {
  rating: number;
  description: string;
  entityName: string;
  entityId: number;
}

function RatingModalV2({
  isOpen,
  onClose,
  entityName,
  entityId,
  refetchRating,
  currentUserRating,
}: {
  isOpen: boolean;
  onClose: () => void;
  entityName: string;
  entityId: number;
  refetchRating: () => void;
  currentUserRating: MyRating | undefined;
}) {
  const intl = useIntl();
  const toast = useToast();

  const initialFormData: {
    rating: number;
    description: string;
    entityName: string;
    entityId: number;
  } = {
    rating: 0,
    description: "",
    entityName,
    entityId: Number(entityId),
  };

  const [formData, setFormData] = useState(
    currentUserRating || initialFormData
  );

  useEffect(() => {
    if (currentUserRating && Object.keys(currentUserRating).length !== 0) {
      // set the form data with the editing data if exists
      const { rating, description, entityName, entityId } = currentUserRating;

      setFormData({ rating, description, entityName, entityId });
    }
  }, [currentUserRating]);

  const resetFormFields = useCallback(() => {
    setFormData(formData);
  }, [formData]);

  const addRating = useCallback(() => {
    post("/rating", formData)
      .then((res: any) => {
        if (res?.id) {
          toast({
            title: intl.formatMessage({ id: "rating.success" }),
            status: "success",
            isClosable: true,
          });
          setFormData({
            rating: 0,
            description: "",
            entityName,
            entityId: Number(entityId),
          });
          refetchRating();
        }

        if (res?.error) {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        }
        onClose();
      })
      .catch((err) => {
        toast({
          title: intl.formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      });
  }, [entityId, entityName, refetchRating, formData, intl, onClose, toast]);

  const updateRating = useCallback(() => {
    put(`/rating/myRating/${entityName}/${entityId}`, {
      rating: formData.rating,
      description: formData.description,
    })
      .then((res: any) => {
        if (res?.success) {
          toast({
            title: intl.formatMessage({ id: "rating.success" }),
            status: "success",
            isClosable: true,
          });
          setFormData({
            rating: 0,
            description: "",
            entityName,
            entityId: Number(entityId),
          });
          refetchRating();
        } else {
          toast({
            title: intl.formatMessage({ id: "wentWrong" }),
            status: "error",
            isClosable: true,
          });
        }
        onClose();
      })
      .catch((err) => {
        toast({
          title: intl.formatMessage({ id: "wentWrong" }),
          status: "error",
          isClosable: true,
        });
      });
  }, [
    entityId,
    entityName,
    refetchRating,
    formData.description,
    formData.rating,
    intl,
    onClose,
    toast,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUserRating && Object.keys(currentUserRating).length !== 0) {
        updateRating();
      } else {
        addRating();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isCentered
      size={"xs"}
      isOpen={isOpen}
      onClose={() => {
        resetFormFields();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent
        p={"10px"}
        maxWidth={"550px"}
        width={"100%"}
        borderRadius={"30px"}
      >
        <ModalCloseButton />
        <ModalBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <Text
              d="inline-block"
              color="brand.900"
              fontSize="18px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              mb="4"
            >
              <FormattedMessage id="addRating" />
            </Text>
            <RatingStars
              icon="star"
              scale={5}
              fillColor="gold"
              size={5}
              initialRating={formData.rating}
              onRatingChange={(rating) => {
                setFormData((current) => {
                  return { ...current, rating: Number(rating) };
                });
              }}
            />
            <Text
              d="block"
              mt="4"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
            >
              <FormattedMessage id="descripeYourExperience" />
            </Text>
            <Box>
              <Textarea
                minHeight={"32"}
                variant="flushed"
                placeholder={intl.formatMessage({ id: "yourMessage" })}
                maxLength={600}
                value={formData.description}
                onChange={(e) => {
                  setFormData((current) => {
                    return { ...current, description: e.target.value };
                  });
                }}
              />
            </Box>
            <Text
              d="inline-block"
              mt="4"
              fontSize="16px"
              fontWeight="bold"
              fontFamily="Calibri (Body)"
              letterSpacing="widest"
            >
              {`${formData?.description?.length}`}
            </Text>
            <Text d="inline-block"> /600</Text>
            <Center my={"32px"}>
              <PrimaryButton
                type="submit"
                disabled={
                  !formData.rating || !formData.entityId || !formData.entityName
                }
              >
                <FormattedMessage id="send" defaultMessage="ارسال" />
              </PrimaryButton>
            </Center>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RatingModalV2;
