import {
  Box,
  Image,
  Text,
  useDisclosure,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import {
  SAL_MAGIC_FAQ_ROUTE,
  PURE_TONES_ROUTE,
  WAVES_ROUTE,
  OTHER_SOURCES_ROUTE,
} from "src/routes";
import SalMagicFigure from "./SalMagicFigure";
import SalMagicVideoPlayer from "./SalMagicPlayer/SalMagicVideoPlayer";
import { useIntl } from "react-intl";

interface Props {
  radius: number;
}

const SalMagicGate: React.FC<Props> = ({ radius }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 465px)");
  const { locale } = useIntl();
  const SAL_MAGIC_DEMO =
    useIntl().locale === "en"
      ? process.env.REACT_APP_SAL_MAGIC_DEMO_URL_EN
      : process.env.REACT_APP_SAL_MAGIC_DEMO_URL_AR;
  console.log("locale", locale);

  const salMagicFigures = [
    {
      id: "__pure_tones_figure",
      name: "Pure Tones",
      src: "/pure-tone.png",
      route: PURE_TONES_ROUTE,
      angle: 0,
    },
    {
      id: "__demos_figure",
      name: "Demo",
      src: "/sal_magic_demo_icon.png",
      route: "",
      angle: 45,
    },
    {
      id: "__waves_figure",
      name: "waves",
      src: "/wave.png",
      route: WAVES_ROUTE,
      angle: 90,
    },
    {
      id: "__faqs_figure",
      name: "FAQs",
      src: "/sal_magic_faq_icon.png",
      route: SAL_MAGIC_FAQ_ROUTE,
      angle: 135,
    },
    {
      id: "__other_sources_figure",
      name: "Other Sources",
      src: "/other-sources.png",
      route: OTHER_SOURCES_ROUTE,
      angle: 180,
    },
  ];

  return (
    <Box
      position={isMobile ? undefined : "relative"}
      style={{
        display: isMobile ? "flex" : undefined,
        flexWrap: isMobile ? "wrap" : undefined,
        justifyContent: isMobile ? "flex-end" : "",
        gap: isMobile ? "1rem" : "",
      }}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        style={{
          minWidth: isMobile ? "100%" : "",
          paddingRight: isMobile && locale === "en" ? "60px" : "",
          paddingLeft: isMobile && locale === "ar" ? "50px" : "",
          alignItems: isMobile ? "flex-end" : "",
        }}
      >
        <Image
          h={"auto"}
          w={120}
          src="https://saladmin.smartsway.com:3000/media/sal_magic_figure.gif"
        />
        <Text
          color="#1f3f93"
          fontFamily="calibri"
          fontWeight="semibold"
          fontSize={22}
        >
          SAL Magic
        </Text>
      </Flex>
      {salMagicFigures.map((figure, index) => {
        const angleInRadians = (figure.angle * Math.PI) / 180;
        const x = radius * Math.cos(angleInRadians);
        const y = radius * Math.sin(angleInRadians);

        return (
          <SalMagicFigure
            key={`${figure.id}-${index}`}
            id={`${figure.id}-${index}`}
            name={figure.name}
            src={figure.src}
            route={figure.route}
            coordinates={{ x, y }}
            onClick={figure.id === "__demos_figure" ? onOpen : undefined}
          />
        );
      })}
      <SalMagicVideoPlayer
        isOpen={isOpen}
        onClose={onClose}
        src={SAL_MAGIC_DEMO}
        loop={true}
        disableSeeking={true}
        autoPlay={true}
      />
    </Box>
  );
};

export default SalMagicGate;
