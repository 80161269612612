import React from "react";
import {
  PieChart,
  Sector,
  Pie,
  Cell,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import { HStack, color } from "@chakra-ui/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { PieStatisticsDataElement } from "src/types";
import useLocale from "src/providers/useLocale";

type PieStatisticsProps = {
  data: PieStatisticsDataElement[];
};

const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index,
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="black"
//       textAnchor={x > cx ? "start" : "end"}
//       dominantBaseline="central"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"black"}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

const PieStatistics = ({ data }: PieStatisticsProps) => {
  const colors = data.map((item) => {
    if (item.name === "Planned") return "#f0f0f0";
    if (item.name === "Done") return "#68D391";
    if (item.name === "Started") return "#C6F6D5";
    if (item.name === "Finish in 3 days") return "#EDED25";
    if (item.name === "Delayed") return "rgba(233, 117, 117, 0.6)";
    return "";
  });

  // const colors = data.map((item) => {
  //   if (item.name === "Done") return "#228b22";
  //   if (item.name === "More than 3 days") return "#90EE90";
  //   if (item.name === "3 days left") return "#FFBB28";
  //   if (item.name === "Passed") return "#D2122E";
  //   return "";
  // });

  const [locale] = useLocale();

  //translate data name if locale is arabic

  const dataTranslated = data.map((item) => {
    if (locale === "ar") {
      if (item.name === "Planned") return { ...item, name: "مخطط" };
      if (item.name === "Done") return { ...item, name: "تم" };
      if (item.name === "Started") return { ...item, name: "بدأ" };
      if (item.name === "Finish in 3 days")
        return { ...item, name: "ينتهي في 3 أيام" };
      if (item.name === "Delayed") return { ...item, name: "متأخر" };
      return "";
    } else return item;
  });

  const [activeIndex, setActiveIndex] = React.useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  return (
    <HStack
      width="100%"
      justifyContent="center"
      alignItems="center"
      borderRadius="10px"
    >
      <PieChart width={430} height={300}>
        <Pie
          style={{ outline: "none" }}
          data={dataTranslated}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          innerRadius={60}
          onMouseEnter={onPieEnter}
        >
          {dataTranslated.map((entry, index) => (
            <Cell
              style={{ outline: "none" }}
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
            />
          ))}
        </Pie>
        <Legend
          style={{
            width: "400px !important",
          }}
        />
      </PieChart>
    </HStack>
  );
};

export default PieStatistics;
